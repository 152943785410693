import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet?.publicKey) {
        const balance = await props.connection.getBalance(wallet?.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet?.publicKey) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }

      const anchorWallet = {
        publicKey: wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction,
      } as anchor.Wallet;

      const { candyMachine, goLiveDate, itemsRemaining } =
        await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection
        );

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  return (
    <main>
      <MintContainer>
      <div id="hero">
        <div className="container-fluid overlay p-5">

          <div
              className="row justify-content-around align-self-center"
              style={{ minHeight: '300px' }}
          >
            <div
                className="col-md-4 p-5 text-center"
                style={{
                  minHeight: '200px',
                  backgroundColor: 'rgba(200,200,200,0.75)',
                }}
            >
              <img
                  src="/img/logo.svg"
                  style={{ width: '80%', margin: 'auto', display: 'block' }}
              />
              <br />
              {wallet.connected && (
                  <p>{shortenAddress(wallet.publicKey?.toBase58() || "")} {(balance || 0).toLocaleString()} SOL </p>
              )}
              {!wallet.connected && <ConnectButton>Connect Wallet</ConnectButton>}
              <br />
              {!wallet.connected ? (
                  <MintButton disabled={true}>Mint</MintButton>
              ) : (
                  <MintButton
                      disabled={isSoldOut || isMinting || !isActive}
                      onClick={onMint}
                      variant="contained"
                  >
                    {isSoldOut ? (
                        "SOLD OUT"
                    ) : isActive ? (
                        isMinting ? (
                            <CircularProgress />
                        ) : (
                            "MINT"
                        )
                    ) : (
                        <Countdown
                            date={startDate}
                            onMount={({ completed }) => completed && setIsActive(true)}
                            onComplete={() => setIsActive(true)}
                            renderer={renderCounter}
                        />
                    )}
                  </MintButton>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*
      <div class="container-fluid p-5" id="roadmap">
      <div class="row d-flex justify-content-end">
      <div class="col-md-6">
      <h1 class="text-center">Roadmap</h1>
      <ul>
      <li>Twitter and Discord launch, initial community building.</li>
      <li>Meme competitions :).</li>
      <li>Whitelisting details released </li>
      <li>Launch and distribution of Solwhips</li>
      <li>Launching on a secondary market  - TBC.</li>
      <li>Continue community building, game development involving SolWhips holders.</li>
      <li>Airdrop for holders.</li>
      <li>The moon the meta verse and beyond!</li>

      </ul>

      </div>
      </div>

      </div>
      <div class="parallax"></div>
      <div class="container-fluid p-5" style="background:grey;" id="cars">

       <div class="row d-flex justify-content-around align-self-center" style="min-height:300px;">

           <div class="col-md-3 car-item">
           <img src="img/1-SOL-CAR-Black.png" style="width:100%;height:auto;">
           <div class="car-description">(1) SOL CAR BLACK</div>
           </div>

           <div class="col-md-3 car-item">
           <img src="img/1-SOL-CAR-Silver.png" style="width:100%;height:auto;">
           <div class="car-description">(1) SOL CAR SILVER</div>
           </div>

           <div class="col-md-3 car-item">
           <img src="img/1-SOL-CAR-Solana-Colours.png" style="width:100%;height:auto;">
           <div class="car-description">(1) SOL CAR Solana</div>
           </div>

       </div>

       <div class="row d-flex justify-content-around align-self-center" style="min-height:300px;">

           <div class="col-md-3 car-item">
           <img src="img/2-SOL-CAR-Green.png" style="width:100%;height:auto;">
           <div class="car-description">(2) SOL CAR GREEN</div>
           </div>

           <div class="col-md-3 car-item">
           <img src="img/2-SOL-CAR-Yellow.png" style="width:100%;height:auto;">
           <div class="car-description">(2) SOL CAR YELLOW</div>
           </div>

           <div class="col-md-3 car-item">
           <img src="img/Camelot-Black-Grey.jpg" style="width:100%;height:auto;">
           <div class="car-description">Camlot Black Grey</div>
           </div>

       </div>


      </div>

      <div class="parallax2"></div>

      <div class="container-fluid p-5 text-white" style="background: black;" id="about">
      <div class="container p-5 gap-3">
      <h1 class="display-1 text-center">About</h1>
      <p class="lead line-double">SolWhips are 2,000 hand drawn uniquely styled Solana themed racing cars, who wouldn’t want a SolWhip? Don’t be fooled though we have a serious side. We are developing the first racing car racing game on Solana. Users will be able to use their SolWhips to race agains other holders betting their Sol.</p>
      <p class="lead line-double">Our short term focus is on having a uniquely smooth launch that rewards our early community members using a whitelist approach. We want to make this an amazing community and a great investment.</p>
      <br>
      <p class="lead line-double">For information on how to join us on our journey follow us on twitter and join our discord </p>
      <a href="#"><i class="bi bi-twitter text-white" style="font-size:26px;margin-right:10px"></i></a>
      <a href="#"><i class="bi bi-discord text-white" style="font-size:26px;margin-right:10px"></i></a>
      </div>
      </div>

      <div class="container-fluid p-5 text-white" style="background: black;" id="faq">

      <h1>FAQ</h1>
      <hr>
      <h2>What is an NFT?</h2>
      <p>
      NFT stands for "non-fungible token." An NFT is basically data that is stored or accounted for in a digital ledger, and that data represents something specific. An NFT can, for example, represent a piece of art, a music album or other types of digital files.
      </p>
      <hr>
      <h2>Where can I buy or sell Solwhips?</h2>
      <p>We're currently listed on Solanart and Digital Eyes.</p>
      <hr>
      <h2>Where can I view my Solwhips?</h2>
      <p>We recommend Phantom wallet or SolFlare. Click on the NFT tab in either wallet and you'll see your Solwhips!</p>
      <hr>
      <h2>What's Solwhips?</h2>
      <p>;)</p>
      <hr>

      </div>
      */}
      </MintContainer>
      {/*<div*/}
      {/*    className="container-fluid p-5 text-white bg-dark"*/}
      {/*    style={{ fontSize: '20px', background: 'black' }}*/}
      {/*>*/}
      {/*  <div className="conatainer p-5 text-center">*/}
      {/*    <h1 style={{ marginBottom: '50px' }}>*/}
      {/*      {' '}*/}
      {/*      For information on how to join us on our journey follow us on*/}
      {/*      twitter and join our discord which will be open soon.*/}
      {/*    </h1>*/}

      {/*    <a href="#">*/}
      {/*      <i*/}
      {/*          className="bi bi-twitter text-white"*/}
      {/*          style={{ fontSize: '52px', marginRight: '10px' }}*/}
      {/*      />*/}
      {/*    </a>*/}
      {/*    <a href="#">*/}
      {/*      <i*/}
      {/*          className="bi bi-discord text-white"*/}
      {/*          style={{ fontSize: '52px', marginRight: '10px' }}*/}
      {/*      />*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*  /!*<div class="row">*!/*/}

      {/*  /!*    <div class="col-md-4">*!/*/}

      {/*  /!*            <img src="img/2-SOL-CAR-Yellow.png" width="60" height="60" class="d-inline-block" alt="">*!/*/}

      {/*  /!*            <br>*!/*/}
      {/*  /!*            <h2>Solwhips</h2>*!/*/}
      {/*  /!*            <br>*!/*/}

      {/*  /!*    </div>*!/*/}
      {/*  /!*           <div class="col-md-4">*!/*/}
      {/*  /!*            <p>*!/*/}
      {/*  /!*                <br>*!/*/}
      {/*  /!*            <a href="#"><i class="bi bi-twitter text-white" style="font-size:26px;margin-right:10px"></i></a>*!/*/}
      {/*  /!*            <a href="#"><i class="bi bi-discord text-white" style="font-size:26px;margin-right:10px"></i></a>*!/*/}
      {/*  /!*            </p>*!/*/}

      {/*  /!*            </div>*!/*/}
      {/*  /!*           <div class="col-md-4 text-right"> <br>*!/*/}

      {/*  /!*            </div>*!/*/}
      {/*  /!*</div>*!/*/}
      {/*</div>*/}
      {/*{wallet.connected && (*/}
      {/*  <p>Address: {shortenAddress(wallet.publicKey?.toBase58() || "")}</p>*/}
      {/*)}*/}

      {/*{wallet.connected && (*/}
      {/*  <p>Balance: {(balance || 0).toLocaleString()} SOL</p>*/}
      {/*)}*/}

      {/*<MintContainer>*/}
      {/*  {!wallet.connected ? (*/}
      {/*    <ConnectButton>Connect Wallet</ConnectButton>*/}
      {/*  ) : (*/}
      {/*    <MintButton*/}
      {/*      disabled={isSoldOut || isMinting || !isActive}*/}
      {/*      onClick={onMint}*/}
      {/*      variant="contained"*/}
      {/*    >*/}
      {/*      {isSoldOut ? (*/}
      {/*        "SOLD OUT"*/}
      {/*      ) : isActive ? (*/}
      {/*        isMinting ? (*/}
      {/*          <CircularProgress />*/}
      {/*        ) : (*/}
      {/*          "MINT"*/}
      {/*        )*/}
      {/*      ) : (*/}
      {/*        <Countdown*/}
      {/*          date={startDate}*/}
      {/*          onMount={({ completed }) => completed && setIsActive(true)}*/}
      {/*          onComplete={() => setIsActive(true)}*/}
      {/*          renderer={renderCounter}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </MintButton>*/}
      {/*  )}*/}
      {/*</MintContainer>*/}

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
